import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {Modal} from "../../common/slds";
import {Formik} from "formik";
import {Log} from "../../common/log";
import {Form} from "../../common/ui/form/formik";
import {CancelButtonField, FormActions, SldsInputField, SubmitButtonField} from "../../common/ui/form/formElements";
import * as React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import UserRolesField from "../../components/user/UserRolesField";
import SingleLookupField from "../../common/ui/lookup/singleLookupField";
import {useAuthContext} from "../../common/context/authContext";

const AddUserDialog = (props) => {
    const auth = useAuthContext()
    let {isOpen, onRequestClose} = props;

    const [createUser] = useMutation(gql`
        mutation($input: UserInput) {
            createUser(input: $input) {
                id
            }
        }
    `, {
        onCompleted: (data) => props.onAdded(data.createUser)
    });

    const organisationListResult = useQuery(gql`
        query($search: String) {
            getOrganisationList(search: $search) {
                id
                name
            }          
        }`, {
        variables: {
            page: {
                offset: 0,
                limit: 10
            }
        },
        skip: true
    });

    return <Modal isOpen={isOpen} onRequestClose={onRequestClose} title={"Create User"}>
        <Formik
            initialValues={{}}
            onSubmit={(values, actions) => {
                return createUser({
                    variables: {
                        input: {
                            login: values.email,
                            name: values.name,
                            email: values.email,
                            organisationId: values.organisation ? values.organisation.id : auth.organisationId(),
                            roles: values.roles
                        }
                    }
                }).then(() => {
                    onRequestClose();
                }, (e) => {
                    actions.setFieldError("global", e);
                    Log.Error("Failed to create hardware", e);
                })
            }}
            validationSchema={Yup.object().strict().shape({
                email: Yup.string().required().trim().email(),
            })}

        >
            <Form>
                <SldsInputField name={"email"} label={"Email"} placeholder={"mail@example.org"} required={true}/>
                <SldsInputField name={"name"} label={"Name"} placeholder={"Max Mustermann"} required={true}/>
                {auth.hasRole("admin") ? <SingleLookupField name={"organisation"} label={"Organisation"} required={true}
                                   loadSuggestions={(keyword) => organisationListResult
                                       .refetch({search: keyword})
                                       .then(result => result.data.getOrganisationList)
                                   }
                                   titleExtractor={it => it.name}
                                   subtitleExtractor={it => it.id}
                /> : null}
                <UserRolesField name={"roles"} label={"Roles"}/>
                <br/>
                <br/>
                <br/>
                <br/>
                <FormActions>
                    <SubmitButtonField>Create</SubmitButtonField>
                    <CancelButtonField onClick={onRequestClose}/>
                </FormActions>
            </Form>
        </Formik>
    </Modal>;
};

AddUserDialog.propTypes = {
    onAdded: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    isOpen: PropTypes.bool,
};

export default AddUserDialog;
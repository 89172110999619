import Lookup from "../../common/ui/lookup/lookup";
import {Icon} from "../../common/slds/icons/icon";
import {insert, useFormikContext} from "formik";
import * as Log from "../../common/log";
import {PillContainerField} from "../../common/ui/form/pillContainerField";
import React from "react";
import PropTypes from "prop-types";
import Pill from "../../common/slds/pills/pill";
import {useAuthContext} from "../../common/context/authContext";

const ROLES = [
    {
        name: "admin",
        displayName: "Admin",
    },
    {
        name: "device-admin",
        displayName: "Device Admin"
    },
    {
        name: "org-admin",
        displayName: "Organisation Admin"
    },
];

const ROLES_WITHOUT_ADMIN = [
    {
        name: "device-admin",
        displayName: "Device Admin"
    },
    {
        name: "org-admin",
        displayName: "Organisation Admin"
    },
];




export default function UserRolesField(props) {
    const auth = useAuthContext();
    const formik = useFormikContext();
    const isAdmin = auth.hasRole("admin")
    const rolesArray = isAdmin ? ROLES : ROLES_WITHOUT_ADMIN

    function getRole(name) {
      return rolesArray.find(it => it.name === name) || {name: name, displayName: name + "*"};
    }


    return <PillContainerField name={"roles"} label={"Roles"}
                               pillLabelExtractor={(it) => getRole(it).displayName}
                               pillPropertiesExtractor={(it) => getRole(it)?.name == "admin" ? {backgroundColor: '#f37c7c'} : {} }

                               renderLookup={() => {
                                   return <Lookup
                                       name={"roles"}
                                       placeholder={"Add Role ..."}
                                       iconExtractor={() => <Icon category="standard" name="user_role" size="small"/>}
                                       titleExtractor={(r) => r.name}
                                       subtitleExtractor={(r) => undefined}
                                       valueExtractor={(option) => option.name}
                                       onLookup={(value) => {
                                           let roles = formik.values["roles"] || [];
                                           formik.setFieldValue("roles", insert(roles, roles.length, value));
                                       }}
                                       loadSuggestions={(value, formik) => {
                                           Log.Debug("Suggestions", value, formik);
                                           const search = value.toLowerCase();
                                           return rolesArray.map((t) => {
                                               const displayName = getRole(t.type).displayName.toLowerCase();
                                               const name = t.name.toLowerCase();

                                               if (name.includes(search) || displayName.includes(search)) {
                                                   return t;
                                               }
                                           })
                                               .filter(it => !!it)
                                               .filter(item => !formik?.form?.values?.roles || formik?.form?.values?.roles?.findIndex(it => item.name === it.name) === -1);
                                       }}
                                   />;
                               }}
    />;
}

UserRolesField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
};

export function UserRolePills(props) {
    let roles = props.roles
    return <div>
        {roles?.map((it, i) => {
            if (it == "admin") {
                return <Pill label={it}
                             key={i}
                             backgroundColor={it == "admin" ? '#f37c7c' : '#e7f3fe'}
                />
            }
               return <Pill label={it}
                                    key={i}
            />
        })}
    </div>

}
import {useAuthContext} from "../../common/context/authContext";
import {useRouteMatch} from "react-router-dom";
import {Log} from "../../common/log";
import VerticalNav, {VerticalNavEntries, VerticalNavEntry, VerticalNavSection, VerticalNavTitle} from "../../common/slds/verticalNavigation/verticalNavigation";
import Url from "../../common/url";
import React from "react";
import PropTypes from "prop-types";
import {useT} from "../../common/i18n";

export function DeviceTypesNav(props) {
    const {deviceTypes, baseUrl, showAll} = props;
    const auth = useAuthContext();
    const match = useRouteMatch();
    const t = useT();

    Log.Debug("match", match);


    return <VerticalNav>
        <VerticalNavSection>
            <VerticalNavTitle>{t("device-types.title" ,"Device Types")}</VerticalNavTitle>
            <VerticalNavEntries>
                {showAll ? <VerticalNavEntry key={"all"} to={Url.join(baseUrl, "all")}>{t("devices.all-types","all")}</VerticalNavEntry> : null}
                {deviceTypes?.map((deviceType, i) => {
                        return <VerticalNavEntry
                            key={i}
                            to={Url.join(baseUrl, deviceType.id)}
                        >
                            {deviceType.displayName}
                        </VerticalNavEntry>;
                    }
                )}
            </VerticalNavEntries>
        </VerticalNavSection>
    </VerticalNav>;
}

DeviceTypesNav.propTypes = {
    baseUrl: PropTypes.string.isRequired,
    deviceTypes: PropTypes.array,
    showAll: PropTypes.bool,
};
import React from "react";
import {Field, Form, Formik} from "formik";
import {
    CancelButtonField,
    FormActions, SldsCheckboxField,
    SldsFormElementCompound,
    SldsFormElementRow,
    SldsInput,
    SldsRadio, SldsRadioGroup,
    SldsSelectField,
    SldsTextarea, SldsTextareaField,
    SubmitButtonField
} from "../../../common/ui/form/formElements";
import * as PropTypes from "prop-types";
import * as log from "../../../common/log";
import {useAuthContext} from "../../../common/context/authContext";
import {NotifyUser} from "../../../common/userNotification";
import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {DeviceDatasourceType} from "../../../model/device";
import Button from "../../../common/slds/buttons/button";
import {DataSourceLinkStatusIcon} from "./datasourceLinkStatusIcon";
import {DeviceAppLookupField} from "../../../components/device/DeviceAppLookupField";
import DeviceTypeLookupField from "../../../components/deviceType/deviceTypeLookupField";
import DeviceTagPillContainerField from "../../../components/device/DeviceTagPillContainerField";
import {useT} from "../../../common/i18n";

const QUERY_DEVICE_TYPES = gql`
    query ($search: String, $orgId: ID) {
        deviceTypes(page: {limit: 10, offset: 0}, search: $search, orgId: $orgId) {
            id
            name
            displayName
            configProperties
        }
    }
`;

const QUERY_DEVICE_DATASOURCE_CONNECTION = gql`
    query deviceConnection($devId: ID!) {
        device(id: $devId) {
            id
            datasource {
                type
                connected
            }
        }
    }
`;

const MUTATE_SYNC_DEVICE_DATASOURCE = gql`
    mutation sync($devId: ID!) {
        syncDeviceWithDatasource(devId: $devId) {
            id
        }
    }
`;

const EditDeviceForm = (props) => {
    const t = useT();
    const auth = useAuthContext();
    const {onUpdate, device} = props;

    const deviceTypesResult = useQuery(QUERY_DEVICE_TYPES);
    const [syncDeviceDs] = useMutation(MUTATE_SYNC_DEVICE_DATASOURCE, {
        variables: {
            devId: device.id,
        },
        refetchQueries: [{
            query: QUERY_DEVICE_DATASOURCE_CONNECTION,
            variables: {
                devId: device.id,
            }
        }]
    });

    log.Debug("EditDeviceForm:", device);

    const syncDeviceWithDatasource = (dev) => {
        syncDeviceDs().then(() => {
            NotifyUser.Info(t("device.settings.notify.datasource-synced","Datasource synchronized."));
        }).catch((e) => {
            NotifyUser.Error(t("device.settings.notify.datasource-synced-failed","Failed to sync Datasource."), e);
        });
    };

    const isAdmin = auth.hasRole("admin");
    const canEditDevice = isAdmin || auth.hasRole("org-admin") ||  auth.hasRole("device-admin");
    return <div>
        <Formik
            initialValues={device}
            enableReinitialize={true}
            initialStatus={{
                readOnly: true,
                canEdit: isAdmin || canEditDevice
            }}
            validate={(values, props) => {
                let errors = {};
                if (!values.name) {
                    errors.name = t("device.settings.validation.name-empty","Name must not be empty");
                }
                return errors;
            }}
            onSubmit={(values, actions) => {
                log.Debug("Submit: ", values, actions);
                onUpdate(values, actions).then(() => {
                }, (err) => {
                    NotifyUser.Error(t("device.settings.notify.device-update-failed","Failed to update device."), err);
                }).finally(() => {
                    actions.setSubmitting(false);
                });
            }}
            render={(formik) => {
                const {readOnly} = formik.status;

                return <Form className="slds-form slds-form-element_stacked">
                    <Field component={SldsInput} name="name" placeholder={t("device.settings.placeholder.name", "Name of the device")} id="name" label={t("device.settings.name","Name")}
                           readOnly={readOnly}/>
                    <Field component={SldsInput} name="addr" placeholder={t("device.settings.placeholder.address","Address of the device")} id="addr" label={t("device.settings.address","Address")}
                           readOnly={readOnly}/>
                    <Field component={SldsInput} name="serial" placeholder={t("device.settings.placeholder.serial","Serial of the device")} id="serial" label={t("device.settings.serial","Serial")}
                           readOnly={readOnly}/>
                    <DeviceTagPillContainerField orgId = {device.organisation?.id || auth.organisationId()} readOnly={readOnly}/>
                    <Field component={SldsTextarea} name="description" placeholder={t("device.settings.placeholder.description","Description of the device")} id="description"
                           label={t("device.settings.description","Description")} rows={4} readOnly={readOnly}/>
                    <DeviceTypeLookupField orgId={device.organisation.id}/>
                     <SldsCheckboxField inlineLabel={t("device.security.certEnforcesDtls","Enforce DTLS if Cert is present")}
                                       name="certEnforcesDtls" id="certEnforcesDtls"/>
                <DeviceAppLookupField
                        name={"app"}
                        orgId={formik.values?.organisation?.id}
                    />
                    <SldsFormElementCompound>
                        <SldsFormElementRow>
                            <SldsSelectField className="slds-size_1-of-2" label={"Datasource"} name={"datasource.type"} options={[
                                {
                                    "value": DeviceDatasourceType[""].value,
                                    "label": DeviceDatasourceType[""].label,
                                },
                                {
                                    "value": DeviceDatasourceType.CHIRPSTACK_GLOBAL.value,
                                    "label": DeviceDatasourceType.CHIRPSTACK_GLOBAL.label,
                                }
                            ]}>
                            </SldsSelectField>
                            {isAdmin && formik.values.datasource.type ? <div className="slds-align-bottom">
                                <DataSourceLinkStatusIcon datasource={formik.values.datasource}/>
                                {!device.datasource.connected ? <Button className="slds-m-left--small" iconName={"sync"} onClick={(e) => syncDeviceWithDatasource(device)}>Sync</Button> : null}
                            </div> : null
                            }
                        </SldsFormElementRow>
                    </SldsFormElementCompound>
                    {/* The old shitty one
                    <Field component={DeviceTypeSelector} readOnly={readOnly || isOrgAdmin}
                           name="deviceType" placeholder="Type of the device"
                           id="device-type" label="Device Type" deviceTypes={deviceTypes}/>
                           */}

                    <FormActions hidden={readOnly}>
                        <SubmitButtonField/>
                        <CancelButtonField/>
                    </FormActions>
                </Form>;
            }}
        />

    </div>;
};

export default EditDeviceForm;


EditDeviceForm.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    device: PropTypes.object.isRequired,
    deviceTypes: PropTypes.array.isRequired,
    canEdit: PropTypes.bool,
};

import React from "react";
import Page from "../../../common/ui/page";
import {Link, useRouteMatch} from "react-router-dom";
import {FieldArray, Formik} from "formik";
import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {useAuthContext} from "../../../common/context/authContext";
import {Form} from "../../../common/ui/form/formik";
import {CancelButtonField, FormActions, SldsButtonGroup, SldsFormElementCompound, SldsFormElementRow, SldsInputField, SubmitButtonField} from "../../../common/ui/form/formElements";
import Button from "../../../common/slds/buttons/button";
import {useNotificationContext} from "../../../notifications/notificationContext";
import Url from "../../../common/url";
import {useT} from "../../../common/i18n";
import {Accordion, AccordionPanel} from "../../../common/ui/accordion";
import DescriptionList, {DescriptionListEntry} from "../../../common/slds/descriptionList/descriptionList";

export function ValidateMeterId(t, value) {
    return /^(\*|[0-9a-fA-F]{8}|\d{11}|[A-Z]{3})$/.test(value) ? undefined : t("org.config.wmbus.validation.meter-id", "Meter ID must be 8 hex characters (0-9,A-F,a-f), 3 characters (A-Z) OMS Manufaturer Code, 11 digits, or '*' for any");
}

export function ValidateMeterKey(t, value) {
    return /^[a-fA-F0-9]{32}$/.test(value) ? undefined : t("org.config.wmbus.validation.key", "Key must be 16 bytes Hex (0-9, A-Z)");
}

export default function WmbusConfigPage(props) {
    const t = useT();
    const match = useRouteMatch();
    const auth = useAuthContext();

    let wmbusKeysResult = useQuery(gql`
        query ($orgId: ID!){
            getOrganisation(id: $orgId) {
                id
                name
                wmbusKeys {
                    keys {
                        id
                        key
                    }
                }
            }
        }
    `, {
        // duplicate id's are causing issues when cached ...
        fetchPolicy: "no-cache",
        variables: {
            orgId: auth.organisationId(),
        }
    });

    let [updateOrganisation] = useMutation(gql`mutation($orgId: ID!, $organisation: OrganisationInput!){
        updateOrganisation(id: $orgId, input: $organisation) {
            wmbusKeys {
                keys {
                    id
                    key
                }
            }
        }}
    `, {
        // duplicate id's are causing issues when cached ...
        fetchPolicy: "no-cache",
        variables: {
            orgId: auth.organisationId(),
        }
    });

    const wmbusKeys = wmbusKeysResult?.data?.getOrganisation?.wmbusKeys;
    const notify = useNotificationContext();
    const actions = <div>
        <Link to={Url.join(match.url, "/import")}>
            {auth.hasRole("admin", "org-admin") ?
                <Button iconName={"upload"} iconPosition={"left"}>{t("common.button.import", "Import")}</Button> : null}
        </Link>
    </div>;

    return <Page
        trail={[<Link to={match.url} key={1}>{t("org.config.nav.wmbus", "wMbus Keys")}</Link>]}
        title={t("org.config.wmbus.title", "wMbus Keys")}
        actions={actions}
    >
        <div className="slds-p-around--small">

            <div className="slds-text-heading--medium slds-m-bottom--x-small">{t("org.config.wmbus.key-list-heading", "wMbus Keys")}</div>
            <Accordion>
                <AccordionPanel id={"0"} summary={t("org.config.wmbus.hints", "Hints")}>
                    <DescriptionList>
                        <DescriptionListEntry label={t("org.config.wmbus.filter", "Filter")}>
                            {t("org.config.wmbus.column-meter-filter-description", "Meter Id of the device, 3 Letter Manufacturer short Code (like KAM/QDS/EFE) or * for a key that should be matched for all devices.")}
                        </DescriptionListEntry>
                        <DescriptionListEntry label={t("org.config.wmbus.key", "Key")}>
                            {t("org.config.wmbus.column-key-description", "Key in Hex Format")}
                        </DescriptionListEntry>
                        <div>{t("org.config.wmbus.hint-text-filter", "All Filter Entries with a matching Meter Id, matching 3 Letter Manufacturer Code (like KAM/QDS/EFE) or a wildcard \"*\" (to match anything) are tried until an valid encryption result is received.")}</div>
                        <div>{t("org.config.wmbus.hint-text-sensus", "For SensusRF devices you can use the 12 digit Meter Id printed on the device. (Without spaces and hyphens.)")}</div>
                        <div>{t("org.config.wmbus.hint-text-star", "A star as Meter Id (\"*\") means that key shall be tried for every Meter Id.")}</div>
                        <div>{t("org.config.wmbus.hint-text-manufacturer", "OMS Manufacturer shortcode means the key is tried for all telegrams coming from devices of that manufacturer.")}</div>
                        <div>{t("org.config.wmbus.hint-text-multiple", "Multiple filters matching are no problem. All keys with Matching Filter are tried until a valid encryption result is received.")}</div>
                    </DescriptionList>
                </AccordionPanel>
            </Accordion>
            <Formik
                onSubmit={(values, actions) => {
                    updateOrganisation({
                        variables: {
                            organisation: {
                                wmbusKeys: JSON.stringify(values.wmbusKeys),
                            }
                        }
                    }).then(() => {
                        notify.info(t("org.config.wmbus.notify.updated-keys", "Updated wMbus Keys"));
                    }).catch((e) => {
                        notify.error(t("org.config.wmbus.notify.update-keys-failed", "Failed to update wMbus Keys"), e);
                    });
                }}
                initialValues={{
                    wmbusKeys: wmbusKeys,
                }}
                initialStatus={{
                    readOnly: true,
                    canEdit: true,
                }}
                enableReinitialize={true}
            >
                <Form>
                    <FieldArray name={"wmbusKeys.keys"}>
                        {(arrayHelpers) => {
                            const values = arrayHelpers.form.values;
                            const {readOnly} = arrayHelpers.form.status;
                            const keys = values?.wmbusKeys?.keys;
                            return <>
                                {keys?.map((k, idx) => {
                                    return <SldsFormElementCompound key={idx}>
                                        <SldsFormElementRow>
                                            <SldsInputField className="slds-size_1-of-3" name={`wmbusKeys.keys.${idx}.id`} label={t("org.config.wmbus.filter", "Filter")}
                                                            validate={(value) => {
                                                                return ValidateMeterId(t, value);
                                                            }}
                                            />
                                            <SldsInputField className="slds-size_1-of-3" name={`wmbusKeys.keys.${idx}.key`} label={t("org.config.wmbus.key", "Key")}
                                                            validate={(value) => {
                                                                return ValidateMeterKey(t, value);
                                                            }}
                                            />

                                            {!readOnly && <SldsButtonGroup className="xslds-col_bump-left slds-shrink slds-align-bottom">
                                                <Button iconName={"arrowup"} iconSize={"small"} disabled={idx === 0} onClick={() => arrayHelpers.move(idx, idx - 1)}/>
                                                <Button iconName={"arrowdown"} iconSize={"small"} disabled={idx === keys.length - 1} onClick={() => arrayHelpers.move(idx, idx + 1)}/>
                                                <Button className={"slds-button_icon-error"} iconName={"delete"} iconSize={"small"} onClick={() => arrayHelpers.remove(idx)}/>
                                            </SldsButtonGroup>}
                                        </SldsFormElementRow>
                                    </SldsFormElementCompound>;
                                })}
                                {!readOnly && <Button iconName={"add"} onClick={() => arrayHelpers.push({})}>{t("org.config.wmbus.add-key-button", "Add Key")}</Button>}
                            </>;
                        }}
                    </FieldArray>
                    <FormActions>
                        <SubmitButtonField/>
                        <CancelButtonField/>
                    </FormActions>
                </Form>
            </Formik>
        </div>
    </Page>;
}
import React, {useEffect, useRef, useState} from "react";
import {EditIconButton, errorFromForm, getFormStatusProps, SldsFormElement, SldsFormElementControl, SldsFormElementLabel, startEdit, validateRequired} from "../form/formElements";
import _ from "underscore";
import {Log} from "../../log";
import {Field, useFormik} from "formik";
import classNames from "classnames";
import PropTypes from "prop-types";


// TODO: We do not handle a changing value in formik, that's no problem yet.
const SingleLookupField = (props) => {
    const {autoFocus, loadSuggestions, valueExtractor, required, validate} = props;
    const [suggestions, setSuggestions] = useState([]);
    const [didSearch, setDidSearch] = useState(false);
    const [open, setOpen] = useState(false);
    const [keyword, setKeyword] = useState("");
    const inputRef = useRef();

    useEffect(() => {
        if (autoFocus) {
            inputRef.current.focus();
        }
    }, [autoFocus]);

    const doUpdateSuggestions = (searchKeyword) => {
        loadSuggestions(searchKeyword).then((suggestions) => {
            Log.Debug("Suggestions", suggestions);
            setSuggestions(suggestions);
            setDidSearch(true);
        });
    };

    const updateSuggestions = _.throttle(doUpdateSuggestions, 300, {leading: false});

    const notFound = !suggestions || suggestions.length === 0;

    return <Field name={props.name} validate={validate || validateRequired(required)}>
        {(fieldProps) => {
            const form = fieldProps.form;
            const error = errorFromForm(form, props);
            let {readOnly, canEdit} = getFormStatusProps(form, props);
            const hasValue = !!fieldProps.field.value;

            //console.log("SingleLookup", "readOnly", readOnly, "open", open);

            return <SldsFormElement canEdit={canEdit} error={!!error} readOnly={readOnly} {...props}>
                <SldsFormElementLabel htmlFor={props.id}>{props.label}{required && !readOnly ? '*' : ''}</SldsFormElementLabel>
                <SldsFormElementControl>
                    {readOnly ?
                        <>
                            <div className="slds-form-element__static" onClick={() => startEdit(fieldProps.form)}>
                                {fieldProps.field.value && props.titleExtractor(fieldProps.field.value)}
                            </div>
                            {canEdit && <EditIconButton onClick={() => startEdit(fieldProps.form)}/>}
                        </>
                        :
                        <div
                            className={classNames("slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click", {"slds-is-open": open})}
                            aria-expanded={open}
                            aria-haspopup="listbox" role="combobox"
                        >
                            <div
                                className={classNames("slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right",
                                    {"slds-input-has-icon slds-input-has-icon_left-right": !open && fieldProps.field.value})}
                                role="none">
                                {!open && fieldProps.field.value && <span
                                    className="slds-icon_container slds-icon-standard-account slds-combobox__input-entity-icon"
                                    title="Account">
                        <svg className="slds-icon slds-icon_small" aria-hidden="true">
                            <use href="/assets/icons/standard-sprite/svg/symbols.svg#account"></use>
                        </svg>
                        <span className="slds-assistive-text">Account</span>
                    </span>}
                                <input type="text" className="slds-input slds-combobox__input"
                                       ref={inputRef}
                                       id="combobox-id-1"
                                       aria-autocomplete="list"
                                       aria-controls="listbox-id-1"
                                       autoComplete="off"
                                       readOnly={hasValue}
                                    //autoFocus={autoFocus}
                                       placeholder={props.placeholder}
                                       onChange={(event) => {
                                           updateSuggestions(event.target.value);
                                           setKeyword(event.target.value);
                                       }}
                                       onFocus={() => {
                                           if (!open && !hasValue) {
                                               setOpen(true);
                                               doUpdateSuggestions(keyword);
                                           }
                                       }}
                                       onInput={() => {
                                           if (!open && !hasValue) {
                                               setOpen(true);
                                               doUpdateSuggestions(keyword);
                                           }
                                       }}
                                       onClick={() => {
                                           if (!open && !hasValue) {
                                               setOpen(true);
                                               doUpdateSuggestions(keyword);
                                           }
                                       }}

                                       onBlur={() => {
                                           setTimeout(() => {
                                               setOpen(false);
                                           }, 500);
                                       }}
                                       onDoubleClick={(event) => {
                                           if (keyword) {
                                               let keyword = props.titleExtractor(fieldProps.field.value);
                                               setKeyword(keyword);
                                           } else {
                                               setKeyword("")
                                           }
                                           setOpen(true);
                                           fieldProps.form.setFieldValue(props.name, null);
                                           inputRef.current.focus();
                                           inputRef.current.select();
                                           doUpdateSuggestions(keyword);
                                       }}
                                       value={hasValue && props.titleExtractor(fieldProps.field.value) || keyword}
                                />
                                {!open && hasValue && <button
                                    className="slds-button slds-button_icon slds-input__icon slds-input__icon_right"
                                    title="Remove selected option"
                                    onClick={() => {
                                        fieldProps.form.setFieldValue(props.name, null);
                                        setKeyword("");
                                        setOpen(true);
                                        doUpdateSuggestions("");
                                        inputRef.current.focus();
                                    }}>
                                    <svg className="slds-button__icon" aria-hidden="true">
                                        <use href="/assets/icons/utility-sprite/svg/symbols.svg#close"></use>
                                    </svg>
                                    <span className="slds-assistive-text">Remove selected option</span>
                                </button>}
                                {(!hasValue || open) && <span
                                    className="slds-icon_container slds-icon-utility-search slds-input__icon slds-input__icon_right">
                       <svg
                           className="slds-icon slds-icon slds-icon_x-small slds-icon-text-default"
                           aria-hidden="true">
                           <use href="/assets/icons/utility-sprite/svg/symbols.svg#search"/>
                       </svg>
                    </span>}
                            </div>
                            <div className="slds-dropdown slds-dropdown_length-with-icon-7 slds-dropdown_fluid"
                                 style={{zIndex: 99999999}}
                                 role="listbox">
                                <ul className="slds-listbox slds-listbox_vertical" role="presentation">
                                    {notFound && didSearch && <div>
                                        Sorry, we could not find what you are looking for
                                    </div>}
                                    {didSearch && suggestions?.map((option, idx) => <li
                                        role="presentation" className="slds-listbox__item"
                                        key={idx}
                                        onClick={() => {
                                            // We use onMouseDown instead, else a "long" click is not recognized because the UI closes too early.
                                        }}
                                        onMouseDown={() => {
                                            Log.Debug("OnClick", option);
                                            setOpen(false);
                                            setSuggestions([]);
                                            let value = option;
                                            if (valueExtractor) {
                                                value = valueExtractor(option);
                                            }

                                            fieldProps.form.setFieldValue(props.name, value);
                                            setKeyword(props.titleExtractor(option));
                                        }}
                                    >
                                        <div
                                            className="slds-media slds-listbox__option slds-listbox__option_entity slds-listbox__option_has-meta"
                                            role="option"
                                        >
                                        <span className="slds-media__figure slds-listbox__option-icon">
                                        <span className="slds-icon_container slds-icon-standard-account">
                                        <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                        <use
                                            href="/assets/icons/standard-sprite/svg/symbols.svg#account"/>
                                        </svg>
                                        </span>
                                        </span>
                                            <span className="slds-media__body">
                                        <span className="slds-listbox__option-text slds-listbox__option-text_entity">
                                        {props.titleExtractor(option)}</span>
                                        <span className="slds-listbox__option-meta slds-listbox__option-meta_entity">
                                        {props.subtitleExtractor(option)}</span>
                                        </span>
                                        </div>
                                    </li>)}
                                </ul>
                            </div>
                        </div>}
                </SldsFormElementControl>
                {error ? <div className="slds-form-element__help">{error}</div> : null}
            </SldsFormElement>;
        }}
    </Field>;
};

SingleLookupField.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    loadSuggestions: PropTypes.func.isRequired,
    titleExtractor: PropTypes.func.isRequired,
    subtitleExtractor: PropTypes.func.isRequired,
    valueExtractor: PropTypes.func,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    required: PropTypes.bool,
    validate: PropTypes.func,
};

export default SingleLookupField;
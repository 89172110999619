/* eslint-disable graphql/template-strings */
import {Link, useRouteMatch} from "react-router-dom";
import {useAuthContext} from "../../../common/context/authContext";
import {useMutation, useQuery} from "@apollo/react-hooks";
import gql from "graphql-tag";
import {useNotificationContext} from "../../../notifications/notificationContext";
import Page from "../../../common/ui/page";
import {Formik} from "formik";
import {Form} from "../../../common/ui/form/formik";
import {CancelButtonField, FormActions, SldsFileSelectorField, SldsFormElement, SldsFormElementCompound, SldsFormElementLabel, SldsFormElementRow, SldsInputField, SubmitButtonField} from "../../../common/ui/form/formElements";
import React from "react";
import {backendUrl} from "../../../common/helper";
import Thumb from "../../../common/slds/fileSelector/thumb";
import {useGraphqlLoadingComponent} from "../../../common/graphql";
import {useT} from "../../../common/i18n";
import roles from "../../../model/roles";

const MUTATION_UPDATE_ORGANISATION = gql`
    mutation($orgId: ID!, $organisation: OrganisationInput!){
        updateOrganisation(id: $orgId, input: $organisation) {
            id
            name
            logoUrl
            dataRetentionTime
        }}
`;
const QUERY_ORGANISATION = gql`
    query($orgId: ID!){
        organisation: getOrganisation(id: $orgId) {
            id
            name
            logoUrl
            dataRetentionTime
        }}
`;


export default function OrganisationSettingsPage(props) {
    const t = useT();
    const match = useRouteMatch();
    const auth = useAuthContext();

    let organisationResult = useQuery(QUERY_ORGANISATION, {
        variables: {
            orgId: auth.organisationId(),
        }
    });

    let [updateOrganisation] = useMutation(MUTATION_UPDATE_ORGANISATION, {
        variables: {
            orgId: auth.organisationId(),
        }
    });


    const org = organisationResult?.data?.organisation;
    const notify = useNotificationContext();
    const logoUrl = org?.logoUrl ? `${backendUrl()}/${org?.logoUrl}` : null;

    const loading = useGraphqlLoadingComponent(organisationResult);
    if (loading) {
        return loading;
    }

    const evaluateRetentionTime = (newRetentionTime) => {
        if (newRetentionTime != org.dataRetentionTime) {
            return newRetentionTime
        }
        return null
    }

    return <Page
        trail={[<Link to={match.url} key={1}>{t("org.config.nav.settings", "Settings")}</Link>]}
        title={t("org.config.settings.title", "Settings")}
    >
        <div className="slds-p-around--small">

            <div className="slds-text-heading--medium slds-m-bottom--x-small">{t("org.config.settings.heading", "Organisation")}</div>
            <Formik
                onSubmit={(values, actions) => {
                    updateOrganisation({
                        variables: {
                            organisation: {
                                name: values.name,
                                logo: values.logo,
                                dataRetentionTime: evaluateRetentionTime(values.dataRetentionTime)
                            }
                        }
                    }).then(() => {
                        notify.info(t("org.config.settings.notify.updated-org", "Updated organisation"));
                    }).catch((e) => {
                        notify.error(t("org.config.settings.notify.update-org-failed", "Failed to update organisation"), e);
                    });
                }}
                initialValues={{
                    ...org,
                    logo: null,
                    logoUrl: logoUrl,
                }}
                initialStatus={{
                    readOnly: true,
                    canEdit: true,
                }}
                enableReinitialize={true}
            >
                {formik => {
                    return <Form>
                        <SldsInputField name={"name"}/>
                        <SldsFormElementCompound label={t("org.config.settings.logo", "Logo")}>
                            <SldsFormElementRow>
                                <SldsFormElement>
                                    <SldsFormElementLabel>{t("org.config.settings.current-logo", "Current Logo")}</SldsFormElementLabel>
                                    <Thumb file={formik.values.logoUrl} height={"40px"}/>
                                </SldsFormElement>
                                <SldsFileSelectorField name={"logo"} label={t("org.config.settings.update-logo", "Update Logo")} buttonLabel={t("file-selector.button.upload-file","Upload Files")} thumbHeight={"40px"} withThumb={true}/>
                            </SldsFormElementRow>
                            <SldsInputField name={"dataRetentionTime"} label={t("org.config.settings.data-retention.time","Data Retention Time")} canEdit={auth.hasRole(roles.ADMIN)} />
                        </SldsFormElementCompound>
                        <FormActions>
                            <SubmitButtonField/>
                            <CancelButtonField/>
                        </FormActions>
                    </Form>;
                }}
            </Formik>
        </div>
    </Page>;
}

//
import React, {useState} from "react";
import {Link, Redirect, Route, useParams} from "react-router-dom";
import HardwareActivationTable from "./hardwareActivationTable";
import Page from "../common/ui/page";
import Button from "../common/slds/buttons/button";
import {useAuthContext} from "../common/context/authContext";
import {useT} from "../common/i18n";

const HardwareActivationPage = (props) => {
    const routeParams = useParams();
    const [activationCode, setActivationCode] = useState(routeParams.activationCode || "");
    const auth = useAuthContext();
    const t = useT()

    return <Page title={t("hardware-activation.titel","Hardware Activation")} trail={[]}>
        <div className="slds-p-horizontal--medium">

            <div className="slds-grid">
                <div className="slds-size_6-of-6 slds-m-right--small">
                    <div className="slds-text-heading--large slds-m-top--large slds-m-bottom--small">{t("hardware-activation.activate-hardware","Activate Hardware")}</div>
                    <input type={"text"} id="activation-code-input" className="slds-input slds-m-bottom--small"
                           placeholder={t("hardware-activation.input.placeholder","Activation Code")}
                           autoFocus={true}
                           value={activationCode}
                           onChange={(e) => setActivationCode(e.target.value)}
                    />
                    <Link to={"/hardwareActivation/" + activationCode}>
                        <Button>{t("hardware-activation.button.show-hardware","Show Hardware")}</Button>
                    </Link>
                </div>
            </div>

            <Route exact path={"/hardwareActivation/org/:orgId"}>
                {routeParams.orgId == auth.organisationId() ? <HardwareActivationTable/> :
                <h1>YOU DONT HAVE PERMISSION TO SEE DEVICES FOR THE ORGANISATION WITH ID: {routeParams.orgId}</h1>}
            </Route>
            <Route exact path={"/hardwareActivation/org/"}>
                <Route exact path={"/hardwareActivation/org/"}>
                    <Redirect to={"/hardwareActivation/org/" + auth.organisationId()}/>
                </Route>
            </Route>
            <Route exact path={"/hardwareActivation/:activationCode"}>
                <HardwareActivationTable/>
            </Route>
            <Route exact path={"/hardwareActivation"}>
                <div className="slds-text-heading--large slds-m-top--large slds-m-bottom--small">{t("hardware-activation.activate-hardware","Activate Hardware")}</div>
                <p>
                    {t("hardware-activation.explanation","Enter your activation code here. You can find it on the delivery note.")}
                </p>
            </Route>
        </div>
    </Page>;
};

export default HardwareActivationPage;
import React from "react";
import VerticalNav, {VerticalNavEntries, VerticalNavEntry, VerticalNavSection, VerticalNavTitle} from "../../common/slds/verticalNavigation/verticalNavigation";
import {useRouteMatch} from "react-router";
import Url from "../../common/url";
import {Redirect, Route, Switch} from "react-router-dom";
import NotFound from "../../common/ui/notFound";
import WmbusConfigPage from "./wmbus/WmbusConfigPage";
import WmbusKeyImportPage from "./wmbus/WmbusKeyImportPage";
import OrganisationSettingsPage from "./settings/OrganisationSettingsPage";
import {OrganisationUsersPage} from "./users/OrganisationUsersPage";
import {useT} from "../../common/i18n";
import UserDetailPage from "../../components/user/userDetailPage";


export default function OrgConfigPage(props) {
    const t = useT();
    const match = useRouteMatch();

    return <div className="slds-container--fluid">
        <div className="slds-grid">
            <div className="slds-col slds-no-flex">
                <VerticalNav>
                    <VerticalNavSection>
                        <VerticalNavTitle>{t("org.config.nav.heading", "Organisation")}</VerticalNavTitle>
                        <VerticalNavEntries>
                            <VerticalNavEntry to={Url.join(match.url, "/settings")}>{t("org.config.nav.settings", "Settings")}</VerticalNavEntry>
                            <VerticalNavEntry to={Url.join(match.url, "/wmbus")}>{t("org.config.nav.wmbus-keys", "wMbus Keys")}</VerticalNavEntry>
                            <VerticalNavEntry to={Url.join(match.url, "/users")}>{t("org.config.nav.users", "Users")}</VerticalNavEntry>
                            <VerticalNavEntry to={"/hardwareActivation/org/"}>{t("org.config.nav.hardware-activation", "Hardware Activation")}</VerticalNavEntry>
                        </VerticalNavEntries>
                    </VerticalNavSection>
                </VerticalNav>
            </div>
            <div className="slds-col">
                <Switch>
                    <Route exact path={Url.join(match.path, "/settings")} component={OrganisationSettingsPage}/>,
                    <Route exact path={Url.join(match.path, "/wmbus")} component={WmbusConfigPage}/>,
                    <Route exact path={Url.join(match.path, "/wmbus/import")} component={WmbusKeyImportPage}/>,
                    <Route exact path={Url.join(match.path, "/users")} component={OrganisationUsersPage}/>,
                    <Route exact path={Url.join(match.path, "/users/:id")} component={UserDetailPage}/>,

                    <Route exact path={Url.join(match.path, "/")}
                           render={() => <Redirect to={Url.join(match.url, "/settings")}/>}/>
                    <Route render={() => <NotFound/>}/>
                </Switch>
            </div>
        </div>
    </div>
}
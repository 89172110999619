import React, {useContext, useState} from "react";
import {FieldArray, Formik} from "formik";
import {Log} from "../../common/log";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {AppContext} from "../../app/appPage";
import {useGraphqlLoadingComponent} from "../../common/graphql";
import {Accordion, AccordionPanel} from "../../common/ui/accordion";
import {CancelButtonField, FormActions, Option, SldsButtonGroup, SldsFormElementCompound, SldsFormElementLabel, SldsFormElementRow, SldsInputField, SldsSelectField, SubmitButtonField} from "../../common/ui/form/formElements";
import {Modal} from "../../common/slds";
import {Form} from "../../common/ui/form/formik";
import moment from "moment";
import {useAuthContext} from "../../common/context/authContext";
import Button from "../../common/slds/buttons/button";
import ScopedNotification from "../../common/slds/scopedNotifications/scopedNotification";
import DescriptionList, {DescriptionListEntry} from "../../common/slds/descriptionList/descriptionList";
import Roles from "../../model/roles";
import {useNotificationContext} from "../../notifications/notificationContext";
import IntegrationFilterLookupField from "../../components/integration/integrationFilterLookupField";
import {useT} from "../../common/i18n";

const MUTATION_CREATE_INTEGRATION_HTTP = gql`
    mutation ($appId: ID, $orgId: ID!, $input: IntegrationHttpInput) {
        integrationHttp: createIntegrationHttp(appId: $appId, orgId: $orgId, input: $input){
            id
        }
    }
`;

const MUTATION_UPDATE_INTEGRATION_HTTP = gql`
    mutation ($id: ID!, $input: IntegrationHttpInput!) {
        integrationHttp: updateIntegrationHttp(id: $id input: $input){
            id
        }
    }
`;

const MUTATION_DELETE_INTEGRATION_HTTP = gql`
    mutation ($id: ID!) {
        integrationHttp: deleteIntegrationHttp(id: $id){
            id
        }
    }
`;

const QUERY_INTEGRATION_HTTP = gql`
    query ($appId: ID, $orgId: ID!) {
        integrationHttp: integrationHttpList(appId: $appId, orgId: $orgId){
            id
            appId
            inputFilter {
                id
                name
            }
            method
            url
            header
            responseStatus
            lastMessage
            lastRequest {
                method
                url
                headers
                body
            }
            lastResponse {
                status
                headers
                body
            }
            lastErrorOccurred
            lastErrorRequest {
                method
                url
                headers
                body
            }
            lastErrorMessage {
                status
                headers
                body
            }
            auth {
                type
                username
                password
                scope
                tokenUrl
            }
        }
    }
`;

export const HttpApiTab = (props) => {
    const t = useT();
    const app = useContext(AppContext);
    const auth = useAuthContext();
    const notify = useNotificationContext();
    const [modalOpen, setModalOpen] = useState(false);
    const orgId = auth.organisationId();
    const canEdit = auth.hasRole(Roles.ADMIN, Roles.ORG_ADMIN);
    const appId = app?.id;
    const integrationHttpResult = useQuery(QUERY_INTEGRATION_HTTP, {
        variables: {
            "appId": appId,
            "orgId": orgId,
        }
    });

    const [createIntegrationMutation] = useMutation(MUTATION_CREATE_INTEGRATION_HTTP, {
        variables: {
            "appId": appId,
            "orgId": orgId,
        },
        refetchQueries: [{
            query: QUERY_INTEGRATION_HTTP,
            variables: {
                "appId": appId,
                "orgId": orgId,
            },
        }]
    });
    const [updateIntegrationMutation] = useMutation(MUTATION_UPDATE_INTEGRATION_HTTP, {
        variables: {},
        refetchQueries: [{
            query: QUERY_INTEGRATION_HTTP,
            variables: {
                "appId": appId,
                "orgId": orgId,
            },
        }]
    });
    const [deleteIntegrationMutation] = useMutation(MUTATION_DELETE_INTEGRATION_HTTP, {
        variables: {},
        refetchQueries: [{
            query: QUERY_INTEGRATION_HTTP,
            variables: {
                "appId": appId,
                "orgId": orgId,
            },
        }]
    });

    const loading = useGraphqlLoadingComponent(integrationHttpResult);
    if (loading) {
        return loading;
    }

    Log.Debug("integrationHttpResult", integrationHttpResult);
    const {integrationHttp} = integrationHttpResult.data;

    return <div className="slds-m-horizontal--x-small">
        <p className="slds-m-bottom--medium">{t("integrations.http.explanation", "A HTTP integration sends device data to a remote server via HTTP(S).")}</p>
        {integrationHttp.length === 0 ? <ScopedNotification theme="light" className="slds-m-bottom--small">{t("integrations.no-integration-yet", "No integration created yet!")}</ScopedNotification> : null}
        <Accordion>
            {integrationHttp.map((integration, i) => {
                Log.Debug("Integration:", integration);
                const lastRequestTime = integration.lastMessage ? moment(integration.lastMessage).format('DD.MM.YYYY HH:mm:ss') : t("common.time.never", "never");
                const lastErrorOccurredTime = integration.lastErrorOccurred ? moment(integration.lastErrorOccurred).format('DD.MM.YYYY HH:mm:ss') : t("common.time.never", "never");
                return <AccordionPanel expanded={false} key={i} id={i}
                                       summary={`${integration.method} ${integration.url}`}
                                       panelContentActions={`${integration.responseStatus} @ ${lastRequestTime}`}
                >
                    <Formik
                        initialValues={{
                            ...integration,
                        }}
                        initialStatus={{
                            readOnly: true,
                            canEdit: canEdit,
                        }}
                        onSubmit={(values, actions) => {
                            Log.Debug("HttpApiTab.submit", values);
                            return updateIntegrationMutation({
                                variables: {
                                    id: integration.id,
                                    input: {
                                        method: values.method,
                                        url: values.url,
                                        header: values.header,
                                        inputFilterId: values.inputFilter?.id || 0, // set 0 to remove, null would be just ignored
                                        auth: {
                                            type: values.auth?.type ? values.auth.type : "",
                                            username: values.auth?.username ? values.auth.username : "",
                                            password: values.auth?.password ? values.auth.password : "",
                                            tokenUrl: values.auth?.tokenUrl ? values.auth.tokenUrl : "",
                                            scope: values.auth?.scope ? values.auth.scope : "",
                                        },
                                    }
                                }
                            }).then((res) => {
                                notify.success(t("integrations.http.notify.updated-integration", "Updated HTTP integration."));
                            }).catch((err) => {
                                notify.error(t("integrations.http.notify.update-integration-failed", "Failed to update HTTP integration."), err);
                            });
                        }}
                    >{(formik) => {
                        return <Form>
                            <IntegrationFilterLookupField name={"inputFilter"} orgId={orgId}/>
                            <SldsSelectField name={"method"} label={t("integrations.http.http-method", "HTTP Method")} required={true}>
                                <Option value={""} label={t("integrations.http.please-select-method", "Please select")}/>
                                <Option value={"post"} label={"POST"}/>
                                <Option value={"get"} label={"GET"}/>
                                <Option value={"put"} label={"PUT"}/>
                                <Option value={"patch"} label={"PATCH"}/>
                                <Option value={"delete"} label={"DELETE"}/>
                            </SldsSelectField>
                            <SldsInputField name={"url"} label={t("integrations.http.target-url", "Target URL")} placeholder={"https://example.com/api/data"} required={true}/>
                            <SldsFormElementCompound>
                                <SldsFormElementLabel>{t("integrations.http.authentication", "Authentication")}</SldsFormElementLabel>
                                <SldsSelectField name={"auth.type"} label={t("integrations.http.auth-method", "Method")} options={
                                    [
                                        {value: "", label: t("integrations.http.auth-method-none", "None")},
                                        {value: "basicauth", label: "Basic Auth"},
                                        {value: "bearer", label: "Bearer Token"},
                                        {value: "oauth", label: "OAuth"},
                                    ]
                                }>
                                </SldsSelectField>
                                {
                                    formik.values?.auth?.type == 'basicauth' ? <div>
                                        <SldsInputField label={t("integrations.http.username", "Username")} name={"auth.username"}/>
                                        <SldsInputField label={t("integrations.http.password", "Password")} type={"password"} name={"auth.password"}/>
                                    </div> : null
                                }
                                {
                                    formik.values?.auth?.type == 'bearer' ? <div>
                                        <SldsInputField label={"Token"} name={"auth.password"} validate={(value) => {return value && value.startsWith("Bearer") ? t("integrations.http.token-no-bearer-prefix", "Insert Token without \"Bearer \" prefix.") : null}}/>
                                    </div> : null
                                }
                                {
                                    formik.values?.auth?.type == 'oauth' ? <div>
                                        <SldsInputField label={"Token URL"} name={"auth.tokenUrl"}/>
                                        <SldsInputField label={"ClientId"} name={"auth.username"}/>
                                        <SldsInputField label={"Secret"} name={"auth.password"}/>
                                        <SldsInputField label={"Scope"} name={"auth.scope"}/>
                                    </div> : null
                                }
                            </SldsFormElementCompound>
                            <FieldArray name={"header"}>{
                                (arrayHelpers => {
                                    const {form} = arrayHelpers;
                                    const values = form.values?.header;
                                    return <>
                                        <SldsFormElementCompound>
                                            <SldsFormElementLabel>{t("integrations.http.headers", "Headers")}</SldsFormElementLabel>
                                            {
                                                values?.map((h, idx) => {
                                                    return <SldsFormElementRow key={idx}>
                                                        <SldsInputField key={idx} name={`header.${idx}`} className="slds-grow"/>
                                                        {!form.status.readOnly ? <SldsButtonGroup className="slds-shrink slds-align-bottom">
                                                            <Button iconName={"arrowup"} iconSize={"small"} disabled={idx === 0} onClick={() => arrayHelpers.move(idx, idx - 1)}/>
                                                            <Button iconName={"arrowdown"} iconSize={"small"} disabled={idx === values.length - 1} onClick={() => arrayHelpers.move(idx, idx + 1)}/>
                                                            <Button className={"slds-button_icon-error"} iconName={"delete"} iconSize={"small"} onClick={() => arrayHelpers.remove(idx)}/>
                                                        </SldsButtonGroup> : null}
                                                    </SldsFormElementRow>;
                                                })
                                            }
                                            <SldsFormElementRow>
                                                {!form.status.readOnly ?
                                                    <Button iconName={"add"} onClick={() => arrayHelpers.push("")}>{t("integrations.http.add-header-button", "Add Header")}</Button>
                                                    : null}
                                            </SldsFormElementRow>
                                        </SldsFormElementCompound>
                                    </>;
                                })
                            }</FieldArray>

                            <FormActions>
                                <SubmitButtonField/>
                                <CancelButtonField/>
                                <Button variant={"destructive"} iconName={"delete"} onClick={() => {
                                    if (window.confirm(t("integrations.confirm.delete-integration", "Delete integration?"))) {
                                        deleteIntegrationMutation({variables: {id: integration.id}})
                                            .then(() => {
                                                notify.success(t("integrations.http.notify.deleted-integration", "Deleted HTTP integration."));
                                            })
                                            .catch((err) => {
                                                notify.error(t("integrations.http.notify.delete-integration-failed", "Failed to delete HTTP integration"), err);
                                            });
                                    }
                                }}>{t("integrations.http.delete-integration-button", "Delete")}</Button>
                            </FormActions>
                        </Form>;
                    }}</Formik>


                    <div className="slds-text-heading--medium slds-m-top--medium slds-m-bottom--xx-small">{t("integrations.http.last-request-response", "Last Request / Response")}</div>
                    <DescriptionList>
                        <DescriptionListEntry label={t("integrations.http.time", "Time")} description={lastRequestTime}/>
                        <DescriptionListEntry label={t("integrations.http.method", "Method")} description={integration.lastRequest?.method}/>
                        <DescriptionListEntry label={t("integrations.http.url", "Url")} description={integration.lastRequest?.url}/>
                        <DescriptionListEntry label={t("integrations.http.status", "Status")} description={integration.lastResponse?.status}/>
                    </DescriptionList>
                    <div className="slds-text-heading--small slds-m-top--small slds-m-bottom--xx-small">{t("integrations.http.request-headers", "Request Headers")}</div>
                    <div className="slds-scrollable_x">
                        {integration.lastRequest?.headers?.map((h, i) => {
                            return <div key={i} className="slds-text-font_monospace">{h}</div>;
                        })}
                    </div>
                    <div className="slds-text-heading--small slds-m-top--small slds-m-bottom--xx-small">{t("integrations.http.request-body", "Request Body")}</div>
                    <textarea className="slds-size_1-of-1 slds-text-font_monospace" readOnly rows={6} defaultValue={integration.lastRequest?.body}/>
                    <div className="slds-text-heading--small slds-m-top--small slds-m-bottom--xx-small">{t("integrations.http.response-headers", "Response Headers")}</div>
                    <div className="slds-scrollable_x">
                        {integration.lastResponse?.headers?.map((h, i) => {
                            return <div key={i} className="slds-text-font_monospace">{h}</div>;
                        })}
                    </div>
                    <div className="slds-text-heading--small slds-m-top--small slds-m-bottom--xx-small">{t("integrations.http.response-body", "Response Body")}</div>
                    <textarea className="slds-size_1-of-1 slds-text-font_monospace" readOnly rows={6} defaultValue={integration.lastResponse?.body}/>


                    <Accordion>
                        <AccordionPanel expanded={false} key={i+"inner"} id={i+"inner"}
                                        summary={<div className="slds-text-heading--medium slds-m-top--medium slds-m-bottom--xx-small">{t("integrations.http.last-error-information", "Last Error Information")}</div>
                                        }
                        >
                            <DescriptionList>
                            <DescriptionListEntry label={t("integrations.http.time", "Time")} description={lastErrorOccurredTime}/>
                            <DescriptionListEntry label={t("integrations.http.method", "Method")} description={integration.lastErrorRequest?.method}/>
                            <DescriptionListEntry label={t("integrations.http.url", "Url")} description={integration.lastErrorRequest?.url}/>
                            <DescriptionListEntry label={t("integrations.http.status", "Status")} description={integration.lastErrorMessage?.status}/>
                        </DescriptionList>
                        <div className="slds-text-heading--small slds-m-top--small slds-m-bottom--xx-small">{t("integrations.http.last-error-request-headers", "Request Headers")}</div>
                        <div className="slds-scrollable_x">
                            {integration.lastErrorRequest?.headers?.map((h, i) => {
                                return <div key={i} className="slds-text-font_monospace">{h}</div>;
                            })}
                        </div>
                        <div className="slds-text-heading--small slds-m-top--small slds-m-bottom--xx-small">{t("integrations.http.request-body", "Request Body")}</div>
                        <textarea className="slds-size_1-of-1 slds-text-font_monospace" readOnly rows={6} defaultValue={integration.lastErrorRequest?.body}/>
                        <div className="slds-text-heading--small slds-m-top--small slds-m-bottom--xx-small">{t("integrations.http.response-headers", "Response Headers")}</div>
                        <div className="slds-scrollable_x">
                            {integration.LastErrorMessage?.headers?.map((h, i) => {
                                return <div key={i} className="slds-text-font_monospace">{h}</div>;
                            })}
                        </div>
                        <div className="slds-text-heading--small slds-m-top--small slds-m-bottom--xx-small">{t("integrations.http.response-body", "Response Body")}</div>
                        <textarea className="slds-size_1-of-1 slds-text-font_monospace" readOnly rows={6} defaultValue={integration.lastErrorMessage?.body}/>

                        </AccordionPanel>
                    </Accordion>
                </AccordionPanel>;
            })}
        </Accordion>

        {canEdit ?
            <Button iconName={"add"} iconCategory={"utility"} onClick={() => setModalOpen(true)}>{t("integrations.add-integration-button", "Add Integration")}</Button>
            : null}

        <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} header={t("integrations.http.create-http-integration-heading", "Create HTTP Integration")}>
            <Formik
                initialValues={{
                    method: "post"
                }}
                onSubmit={(values, actions) => {
                    Log.Debug("HttpApiTab.submit", values);
                    createIntegrationMutation({
                        variables: {
                            input: {
                                method: values.method,
                                url: values.url,
                            }
                        }
                    }).then(() => {
                        notify.success(t("integrations.http.created-integration", "Created HTTP integration."));
                        setModalOpen(false);
                    }).catch((err) => {
                        notify.error(t("integrations.http.create-integration-failed", "Failed to create HTTP integration."), err);
                    }).finally(() => {
                        actions.setSubmitting(false);
                    });

                }}
            >
                <Form>
                    <SldsSelectField name={"method"} label={t("integrations.http.http-method", "HTTP Method")} required={true}>
                        <Option value={""} label={t("integrations.http.please-select-method", "Please select")}/>
                        <Option value={"post"} label={"POST"}/>
                        <Option value={"get"} label={"GET"}/>
                        <Option value={"put"} label={"PUT"}/>
                        <Option value={"patch"} label={"PATCH"}/>
                        <Option value={"delete"} label={"DELETE"}/>
                    </SldsSelectField>
                    <SldsInputField name={"url"} label={t("integrations.http.target-url", "Target URL")} placeholder={"https://example.com/api/data"} required={true} autoFocus={true}/>

                    <FormActions>
                        <SubmitButtonField>Save</SubmitButtonField>
                        <CancelButtonField onClick={() => setModalOpen(false)}/>
                    </FormActions>
                </Form>
            </Formik>
        </Modal>
    </div>;
};
import React, {useEffect, useState} from "react";
import Page from "../../../common/ui/page";
import {Accordion, AccordionPanel} from "../../../common/ui/accordion";
import DescriptionList, {DescriptionListEntry} from "../../../common/slds/descriptionList/descriptionList";
import {Form} from "../../../common/ui/form/formik";
import {FormActions, SldsFileSelectorField, SldsRadioField, SldsRadioGroup, SubmitButtonField} from "../../../common/ui/form/formElements";
import DataTable, {Col, HeaderCol, Row, TableBody, TableHead} from "../../../common/slds/dataTable";
import {Icon} from "../../../common/slds/icons/icon";
import Tooltip from "../../../common/slds/tooltip/tooltip";
import {Formik} from "formik";
import {Log} from "../../../common/log";
import Papa from "papaparse";
import gql from "graphql-tag";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {useAuthContext} from "../../../common/context/authContext";
import {useT} from "../../../common/i18n";
import {ValidateMeterId, ValidateMeterKey} from "./WmbusConfigPage";
import {useNotificationContext} from "../../../notifications/notificationContext";
import Button from "../../../common/slds/buttons/button";


const exampleCsv = `meterId;key
42345678;4A111111111111111111111111111111
*;5A222211111111111111111111111111
22345678;21A11111111111111111111111111111`

function useCsvImport() {
    let [results, setResults] = useState("");

    return {
        results: results,

        /**
         * @param file {File}
         */
        loadFile: (file) => {
            Log.Debug("Loading CSV File");
            Papa.parse(file, {
                skipEmptyLines: true,
                complete: (results) => {
                    Log.Debug("Loading CSV File - DONE");
                    setResults(results);
                }
            });
        }
    };
}

const ImportStatus = {
    Pending: "pending",
    Done: "done",
    Failed: "failed",
    Skipped: "skipped",
};


const HandleExisting = {
    override: {
        value: "OVERRIDE"
    },
    skip: {
        value: "SKIP"
    },
    duplicate: {
        value: "DUPLICATE"
    },
};

/**
 * meterId, key pair to be imported
 */
class KeyPair {
    keyId;
    key;
    existing; // is there already an existing entry for the id?
    valid;

    /**
     * Function that is calles when the device should be created in backend.
     *
     * @returns {undefined | Promise<T>}
     */
    createDeviceHandler;

    constructor(keyId, key) {
        this.keyId = keyId;
        this.key = key;
        this.importStatus = ImportStatus.Pending;
    }
}

function downloadExampleCsv(){
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(exampleCsv));
    element.setAttribute('download', "mbusKeysExample.csv");

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export default function WmbusKeyImportPage(props) {
    const t = useT();
    const notify = useNotificationContext()
    const auth = useAuthContext();
    const [newKeys, setNewKeys] = useState([]);
    const [updateStatus, setUpdateStatus] = useState(0);

    let organisation = useQuery(gql`
        query ($orgId: ID!){
            getOrganisation(id: $orgId) {
                id
                name
                wmbusKeys {
                    keys {
                        #duplicate id's are causing issues when cached ...
                        keyId: id
                        key
                    }
                }
            }
        }
    `, {
        variables: {
            // duplicate id's are causing issues when cached ...
            fetchPolicy: "no-cache",
            orgId: auth.organisationId()
        }
    });


    let [updateOrganisation] = useMutation(gql`mutation($orgId: ID!, $organisation: OrganisationInput!){
        updateOrganisation(id: $orgId, input: $organisation) {
            wmbusKeys {
                keys {
                    #duplicate id's are causing issues when cached ...
                    keyId: id
                    key
                }
            }
        }}
    `, {
        variables: {
            // duplicate id's are causing issues when cached ...
            fetchPolicy: "no-cache",
            orgId: auth.organisationId(),
        }
    });


    const csvImport = useCsvImport();

    const createKeyUpdateHandler = (d, meta) => {
        //merge existing keys with new Keys!
    }


    useEffect(() => {
        const wmbusKeys = organisation?.data?.getOrganisation?.wmbusKeys?.keys || [];

        if (csvImport.results) {
            const data = csvImport.results.data;
            let colNames = [];
            colNames = data[0];
            let idxMeterId = colNames.findIndex(v => v.toLowerCase() === "meterid");
            let idxKey = colNames.findIndex(v => v.toLowerCase() === "key");

            let keyPairs = [];
            //let deviceQueries = [];
            for (let i = 1; i < data.length; i++) {
                let rec = data[i];
                // Note that rec[-1] === undefined
                const meterId = rec[idxMeterId];
                if (!meterId) {
                    Log.Debug("Skip", idxMeterId, rec, data[0]);
                    continue;
                }
                let key = rec[idxKey];
                if (!key) {
                    Log.Debug("Skip", idxKey, rec, data[0]);
                    continue;
                }
                //pad keys that are to short with leading zeros
                key = key.padStart(32, '0')
                let k = new KeyPair(meterId, key)
                const found = wmbusKeys.find(element => element.keyId == meterId)
                k.existing = found ? true : false;


                let meterIdValid = ValidateMeterId(t, meterId);
                let validationErrors = [];
                if (meterIdValid !== undefined) {
                    validationErrors.push(meterIdValid);
                }

                let keyValid = ValidateMeterKey(t, key);
                if (keyValid !== undefined) {
                    validationErrors.push(keyValid);
                }

                k.valid = validationErrors

                keyPairs.push(k);
            }
            setNewKeys(keyPairs);


        }
    }, [csvImport.results, updateStatus]);

    const runImport = (newKeys, meta) => {
        const wmbusKeys = organisation.data.getOrganisation.wmbusKeys?.keys || [];
        newKeys.forEach(newKey => {
            if (newKey.valid.length <= 1) {
                const foundIdx = wmbusKeys.findIndex((element) => element.keyId == newKey.keyId)
                if (foundIdx > -1 && meta.handleExisting == HandleExisting.override.value) {
                    wmbusKeys[foundIdx].key = newKey.key
                } else if (foundIdx == -1 || meta.handleExisting == HandleExisting.duplicate.value) {
                    wmbusKeys.push({
                        keyId: newKey.keyId,
                        key: newKey.key,
                    })
                }
            }
        })

        let keysGqlInput = wmbusKeys.map((o) => {
            return {
                id: o.keyId,
                key: o.key
            }
        }
    );

        updateOrganisation({
            variables: {
                organisation: {
                    wmbusKeys: JSON.stringify({keys: keysGqlInput}),
                }
            }
        }).then(() => {
            notify.info(t("wmbus-key-import.import-success-notification", "imported keys"))
            setUpdateStatus(1)
        }).catch((e) => {
            notify.error(t("wmbus-key-import.import-error-notification","error on key import: ") + e)
            setUpdateStatus(e)
        });
    }

    let statusElement = null
    if (updateStatus == 1) {
        statusElement = <Tooltip left="-10px" top="-50px" content={t("wmbus-key-import.status-tooltip-success", "Success")}>
            {t("wmbus-key-import.status-tooltip-report", "Report:")} <Icon name="check" size={"small"} className="slds-m-right--x-small"/>
        </Tooltip>
    } else if (updateStatus != 0 && updateStatus != 1) {
        statusElement = <Tooltip left="-10px" top="-50px" content={t("wmbus-key-import.status-tooltip-import-failed", "Import Failed: {{status}}", {status: updateStatus.toString()})}>
            {t("wmbus-key-import.status-tooltip-report", "Report:")} <Icon name="error" size={"small"} className="slds-m-right--x-small"/>
        </Tooltip>
    } else {
        statusElement = null
    }

    return <Page
        title={t("wmbus-key-import.title", "Import wMbus Keys")}
        trail={[]}
        withPadding={true}
    >
        <Formik
            initialValues={{
                file: "",
                handleExisting: HandleExisting.skip.value,
            }}
            onSubmit={(values, helpers) => {
                runImport(newKeys, values);
            }}
        >{(form) => {
            const file = form.values.file;

            Log.Debug("File:", file);
            return <Form>
                <SldsFileSelectorField label={t("wmbus-key-import.csv-file", "CSV File")} buttonLabel={t("wmbus-key-import.csv-file-button", "Select CSV")} name={"file"} required={false}
                                       accept={".csv"} onFileChange={(file) => {
                    csvImport.loadFile(file);
                    setUpdateStatus(0)
                }}/>
                <Accordion>
                    <AccordionPanel id={"0"} summary={t("wmbus-key-import.csv-format", "CSV Format and Example")}>
                        <div>{t("wmbus-key-import.column-names-list-title", "Column names in first row:")}</div>
                        <DescriptionList>
                            <DescriptionListEntry label={t("wmbus-key-import.filter", "Filter")}>
                                {t("wmbus-key-import.column-filter-description", "Meter Id of the device, 3 LetterOMS Manufacturer Code or * for a key that should be matched for all devices")}
                            </DescriptionListEntry>
                            <DescriptionListEntry label="key">
                                {t("wmbus-key-import.column-key-description", "Key in Hex Format")}
                            </DescriptionListEntry>
                        </DescriptionList>
                        <div>{t("org.config.wmbus.hints", "Hints")}:</div>
                        <div>{t("org.config.wmbus.hint-text-filter", "All Filter Entries with a matching Meter Id, matching 3 Letter Manufacturer Code (like KAM/QDS/EFE) or a wildcard \"*\" (to match anything) are tried until an valid encryption result is received.")}</div>
                        <div>{t("org.config.wmbus.hint-text-sensus", "For SensusRF devices you can use the 12 digit Meter Id printed on the device. (Without spaces and hyphens.)")}</div>
                        <div>{t("org.config.wmbus.hint-text-star", "A star as Meter Id (\"*\") means that key shall be tried for every Meter Id.")}</div>
                        <div>{t("org.config.wmbus.hint-text-manufacturer", "OMS Manufacturer shortcode means the key is tried for all telegrams coming from devices of that manufacturer.")}</div>
                        <div>{t("org.config.wmbus.hint-text-multiple", "Multiple filters matching are no problem. All keys with Matching Filter are tried until a valid encryption result is received.")}</div>
                        <Button onClick={() => downloadExampleCsv()}>{t("wmbus-key-import.download-button","Download Example CSV")}</Button>
                    </AccordionPanel>
                </Accordion>

                <SldsRadioGroup label={t("wmbus-key-import.collision-handling-radio-title", "How to Handel collisions with Exiting Keys:")} required={true}>
                    <SldsRadioField id={HandleExisting.skip.value} name={"handleExisting"}
                                    value={HandleExisting.skip.value}
                                    label={t("wmbus-key-import.handle-existing-skip", "Skip Duplicates")}/>
                    <SldsRadioField id={HandleExisting.duplicate.value} name={"handleExisting"}
                                    value={HandleExisting.duplicate.value}
                                    label={t("wmbus-key-import.handle-existing-add", "Add Duplicate")}/>
                    <SldsRadioField id={HandleExisting.override.value} name={"handleExisting"}
                                    value={HandleExisting.override.value}
                                    label={t("wmbus-key-import.handle-existing-override", "Override Duplicates")}/>
                </SldsRadioGroup>
                <FormActions>
                    <SubmitButtonField iconName={"play"}>{t("wmbus-key-import.run-import-button", "Run Import")}</SubmitButtonField>
                </FormActions>

                <div className="slds-text-heading--medium slds-m-top--small">{statusElement ? statusElement : t("wmbus-key-import.preview", "Preview")}</div>
                <DataTable fixedLayout={false}>
                    <TableHead>
                        <HeaderCol>{t("wmbus-key-import.filter", "Filter")}</HeaderCol>
                        <HeaderCol>{t("wmbus-key-import.key", "Key")}</HeaderCol>
                        <HeaderCol>{t("wmbus-key-import.existing", "Existing")}</HeaderCol>
                        <HeaderCol>{t("wmbus-key-import.valid", "Valid")} <Tooltip left="-10px" top="-50px" content={t("wmbus-key-import.tooltip-invalid-are-skipped", "Invalid Entry's will be skipped.")}>
                            <Icon name="info" size={"small"} className="slds-m-right--x-small"/>
                        </Tooltip></HeaderCol>
                    </TableHead>
                    <TableBody>
                        {newKeys.map((e, i) => {
                            return <Row key={i}>
                                <Col>{e.keyId}</Col>
                                <Col>{e.key}</Col>
                                <Col>{e.existing ?
                                    <Tooltip left="-10px" top="-50px"
                                             content={t("wmbus-key-import.existing-key-for-filter", "Existing Key Entry for Filter found!")}>
                                        <Icon name="check" size={"small"}/></Tooltip> : <Icon name="close" size={"small"}/>}
                                </Col>
                                <Col wrap={true}>
                                    {e.valid.length <= 1 ?
                                        <Icon name="check" size={"small"} className="slds-m-right--x-small"/> :
                                        <div className={"slds-grid"}>
                                            <Icon name="close" size={"small"} className="slds-m-right--x-small"/>
                                            <ul> {e.valid.map((v, i) => {
                                                return <li key={i}>{v}</li>
                                            })
                                            }
                                            </ul>
                                        </div>
                                    }
                                </Col>
                            </Row>;
                        })}
                    </TableBody>
                </DataTable>
            </Form>;
        }}</Formik>
    </Page>
}
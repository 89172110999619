import AppsPage from "./applications/appsPage";
import NewAppPage from "./applications/newAppPage";
import AppDetailPage from "./applications/appDetailPage";
import React from "react";
import {Log} from "../common/log";
import {Redirect, Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import Url from "../common/url"
import HardwareListPage from "./hardware/HardwareListPage";
import HardwareDetailPage from "./hardware/HardwareDetailPage";
import NotFound from "../common/ui/notFound";
import ActivationGroupsListPage from "./activationGroups/activationGroupsListPage";
import ActivationGroupDetailPage from "./activationGroups/activationGroupDetailPage";
import UsersListPage from "./users/usersListPage";
import OrganisationsListPage from "./organisations/organisationListPage";
import OrganisationDetailPage from "./organisations/organisationDetailPage";
import AdminPage from "./admin/adminPage";
import VerticalNav, {VerticalNavEntries, VerticalNavEntry, VerticalNavSection, VerticalNavTitle} from "../common/slds/verticalNavigation/verticalNavigation";
import ImportHardwarePage from "./hardware/importHardwarePage";
import {AdminTokenPage} from "./admin/adminTokenTab";
import {useT} from "../common/i18n";
import {HardwareBatteryPage} from "./hardware/HardwareBatteryPage";
import CronLogPage from "./cronJobs/cronLogPage";
import {FirmwareManagmentPage} from "./firmware/firmewareManagmentPage";
import UserDetailPage from "../components/user/userDetailPage";

let keyGen = 100;

function ConfigurationPage(props) {
    const t = useT();
    const location = useLocation();
    const match = useRouteMatch();
    Log.Debug("location", location);
    Log.Debug("match", match);

    const navigation = [
        {
            label: t("configuration.nav.applications", "Applications"),
            url: Url.join(match.url, "/applications"),
            routes: [
                <Route exact path={Url.join(match.path, "/applications")} component={AppsPage} key={keyGen++}/>,
                <Route exact path={Url.join(match.path, "/applications/new")} component={NewAppPage} key={keyGen++}/>,
                <Route exact path={Url.join(match.path, "/applications/:id")} component={AppDetailPage} key={keyGen++}/>,
            ]
        },
        {
            label: t("configuration.nav.hardware", "Hardware"),
            url: Url.join(match.url, "/hardware"),
            routes: [
                <Route exact path={Url.join(match.path, "/hardware")} component={HardwareListPage} key={keyGen++}/>,
                <Route exact path={Url.join(match.path, "/hardware/import")} component={ImportHardwarePage} key={keyGen++}/>,
                <Route exact path={Url.join(match.path, "/hardware/:id")} component={HardwareDetailPage} key={keyGen++}/>,
            ]
        },        {
            label: t("configuration.nav.hardwareBattery", "Hardware Battery"),
            url: Url.join(match.url, "/hardwareBattery"),
            routes: [
                <Route exact path={Url.join(match.path, "/hardwareBattery")} component={HardwareBatteryPage} key={keyGen++}/>,
            ]
        },
        {
            label: t("configuration.nav.firmwareManagment", "Firmware"),
            url: Url.join(match.url, "/firmware"),
            routes: [
                <Route exact path={Url.join(match.path, "/firmware")} component={FirmwareManagmentPage} key={keyGen++}/>,
            ]
        },
        {
            label: t("configuration.nav.activation-groups", "Activation Groups"),
            url: Url.join(match.url, "/activationGroups"),
            routes: [
                <Route exact path={Url.join(match.path, "/activationGroups")} component={ActivationGroupsListPage} key={keyGen++}/>,
                <Route exact path={Url.join(match.path, "/activationGroups/:id")}
                       component={ActivationGroupDetailPage} key={9}/>
            ]
        },
        {
            label: t("configuration.nav.users", "Users"),
            url: Url.join(match.url, "/users"),
            routes: [
                <Route exact path={Url.join(match.path, "/users")} component={UsersListPage} key={keyGen++}/>,
                <Route path={Url.join(match.path, "/users/:id")} component={UserDetailPage} key={keyGen++}/>
            ]
        },
        {
            label: t("configuration.nav.organisations", "Organisations"),
            url: Url.join(match.url, "/organisations"),
            routes: [
                <Route exact path={Url.join(match.path, "/organisations")} component={OrganisationsListPage} key={keyGen++}/>,
                <Route exact path={Url.join(match.path, "/organisations/:id")} component={OrganisationDetailPage} key={keyGen++}/>
            ]
        },
        {
            label: t("configuration.nav.cron-log", "Cron Log"),
            url: Url.join(match.url, "/cronLog"),
            routes: [
                <Route exact path={Url.join(match.path, "/cronLog")} component={CronLogPage} key={keyGen++}/>,
            ]
        },
        {
            label: t("configuration.nav.index-management", "Index Management"),
            url: Url.join(match.url, "/admin"),
            routes: [
                <Route exact path={Url.join(match.path, "/admin")} component={AdminPage} key={keyGen++}/>,
            ]
        },
        {
            label: t("configuration.nav.admin-tokens","Admin Tokens"),
            url: Url.join(match.url, "/token"),
            routes: [
                <Route exact path={Url.join(match.path, "/token")} component={AdminTokenPage} key={keyGen++}/>,
            ]
        }

    ];

    return <>
        <div className="slds-container--fluid">
            <div className="slds-grid">
                <div className="slds-col slds-no-flex">
                    <VerticalNav>
                        <VerticalNavSection>
                            <VerticalNavTitle>Configuration</VerticalNavTitle>
                            <VerticalNavEntries>
                                {navigation.map((nav, i) => {
                                        return <VerticalNavEntry key={i} to={nav.url}>{nav.label}</VerticalNavEntry>
                                    }
                                )}
                            </VerticalNavEntries>
                        </VerticalNavSection>
                    </VerticalNav>
                </div>
                <div className="slds-col">
                    <Switch>
                        {
                            navigation.map((nav, i) => {
                                return nav.routes;
                            })
                        }
                        <Route exact path={Url.join(match.path, "/")}
                               render={() => <Redirect to={Url.join(match.url, "/applications")}/>}/>
                        <Route render={() => <NotFound/>}/>
                    </Switch>
                </div>
            </div>
        </div>
    </>
}

export default ConfigurationPage
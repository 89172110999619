import React, {useContext} from "react";
import {platformConfig} from "../platformConfig";
import moment from "moment";
import {Log} from "../log";
import {backendUrl} from "../helper";

function saveToLocalStorage(key, value) {
    if (value) {
        localStorage.setItem(key, value);
    } else {
        localStorage.removeItem(key);
    }
}

let oauthTokenUrl = backendUrl() + "/oauth/token";

export const decodeToken = (str) => {
    str = str.split('.')[1];

    str = str.replace('/-/g', '+');
    str = str.replace('/_/g', '/');
    switch (str.length % 4) {
        case 0:
            break;
        case 2:
            str += '==';
            break;
        case 3:
            str += '=';
            break;
        default:
            throw 'Invalid token';
    }

    str = (str + '===').slice(0, str.length + (str.length % 4));
    str = str.replace(/-/g, '+').replace(/_/g, '/');

    str = decodeURIComponent(escape(atob(str)));

    str = JSON.parse(str);
    return str;
};

export class AuthState {

    constructor() {
        this.changeListeners = [];
        this.authenticated = false;
        this.tokenParsed = null;
        this.token = null;
        this.refreshToken = null;

        this.init(localStorage.getItem("token"), localStorage.getItem("refresh_token"));
    };

    init = (token, refreshToken) => {
        // TODO: Get Time from server and calculate
        this.token = token;
        this.refreshToken = refreshToken;
        if (token) {
            try {
                this.tokenParsed = decodeToken(token);
                this.authenticated = true;
                Log.Debug("TokenParsed", this.tokenParsed);
                moment.locale(this.locale());
            } catch (err) {
                this.token = null;
                this.tokenParsed = null;
                this.authenticated = false;
            }
        } else {
            this.token = null;
            this.tokenParsed = null;
            this.authenticated = false;
        }

        for (let i = 0; i < this.changeListeners.length; ++i) {
            this.changeListeners[i](this);
        }

    };

    onChange = (listener) => {
      this.changeListeners.push(listener);
    };

    refreshTheToken = () => {
        if (!this.refreshToken) {
            throw new Error("No refresh token available");
        }
        let body = {
            grant_type: "refresh_token",
            token: this.refreshToken,

        }
        return fetch(oauthTokenUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Basic ${btoa("lobaro-dashboard:")}`
            },
            body: JSON.stringify(body)
        })
            .then(this.handleTokenResponse)
    };

    saveToken = (access_token, refresh_token) => {
        this.init(access_token, refresh_token);
        saveToLocalStorage("token", access_token);
        saveToLocalStorage("refresh_token", refresh_token);
    };

    handleTokenResponse = (resp) => {
        if (resp.status !== 200) {
            Log.Debug("Didn't work... :(", resp);
            this.saveToken(null, null);
            throw new Error("Got response code " + resp.status);
        }
        return resp.json().then(json => this.saveToken(json.access_token, json.refresh_token))
    };

    login = (username, password) => {
        let body = {
            grant_type: "password",
            username: username,
            password: password
        }
        return fetch(oauthTokenUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Basic ${btoa("lobaro-dashboard:")}`
            },
            body:  JSON.stringify(body)
        })
            .then(this.handleTokenResponse)
    };

    impersonate = (username) => {
        let body = {
            grant_type: "impersonate",
            username: username,
            token: this.token
        }
        return fetch(oauthTokenUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Basic ${btoa("lobaro-dashboard:")}`
            },
            body: JSON.stringify(body)
        })
            .then(this.handleTokenResponse)
    };

    locale = () => {
        const defaultLocale = "en";
        if (!this.tokenParsed) {
            return defaultLocale;
        }
        return this.tokenParsed.locale || defaultLocale;
    };

    name = () => {
        if (platformConfig.devMode) {
            return "Dev User";
        }
        if (!this.tokenParsed) {
            return "-";
        }
        return this.tokenParsed.name || "- no name -";
    };

    // userId as string, to be compatible with graphQl
    userId = () => {
        if (!this.tokenParsed) {
            return "0";
        }
        return `${this.tokenParsed.uid}` || "0";
    };

    organisationId = () => {
        if(!this.tokenParsed) {
            return 0;
        }
        // TODO: org is deprecated, but kept for one version for migration reasons
        return this.tokenParsed.org || this.tokenParsed.orgId || 0;
    };

    isLoggedIn = () => {
        if (platformConfig.devMode) {
            return true;
        }
        return this.authenticated && !!this.tokenParsed;
    };

    hasRole = (...roles) => {
        if (platformConfig.devMode) {
            Log.Debug("Allowing role in devMode without checking", roles);
            return true;
        }

        if (!roles) {
            return false;
        }

        if (!this.tokenParsed) {
            return false;
        }

        // Old Keycloak roles
        const resourceAccess = this.tokenParsed.resource_access;
        if (resourceAccess && resourceAccess["lobaro-dashboard"]) {
            if (resourceAccess["lobaro-dashboard"].roles.includes(roles)) {
                return true;
            }
        }

        return roles.some((r) => {
            return this.tokenParsed.roles && this.tokenParsed.roles.includes(r);
        })
    };

    logout = () => {
        this.saveToken(null, null);
    }
}

const authState = new AuthState();

/**
 * @return AuthState
 */
export function getAuthState() {
    return authState;
}

export const AuthContext = React.createContext(null);

/**
 * @return AuthState
 */
export function useAuthContext() {
    return useContext(AuthContext);
}